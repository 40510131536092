import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { apiCaller } from '@/redux/query';
import bannerSlice, { bannerNoticeEmbeddedAppSelector } from '@/redux/slice/banner.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon } from '@shopify/polaris-icons';
import { useMemo } from 'react';
import { link } from '@/constants/link';
import { useDispatch, useSelector } from 'react-redux';
import FAQ from './FAQ';
import Overview from './Overview';
import { DashboardStyled } from './styled';
import ThingsToDoNext from './thingsToDoNext';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const bannerNoticeEmbeddedApp = useSelector(bannerNoticeEmbeddedAppSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: 'Contact support',
        description: (
          <span>
            We provide <b>24/7</b> support, feel free to contact us if you get any problems with the app.
          </span>
        ),
        onAction: () => {
          const sendText = 'I need assistance with using Age Verify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreAgeVerification']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: 'Chat with us',
        icon: ChatIcon,
      },
      {
        title: 'Read user guideline',
        description: 'Step-by-step instruction articles to guide you in setting up rules in the easiest way.',
        onAction: () => {
          window.open('https://docs-age.ipblocker.io/');
        },
        btnContent: 'Read user guideline',
        icon: BookIcon,
      },
      {
        title: 'Discover use cases',
        description: 'Explore our helpful articles on various rule use cases to assist you in verifying age of your store.',
        onAction: () => {
          window.open('https://docs-age.ipblocker.io/settings');
        },
        btnContent: 'View use cases',
        icon: GlobeAsiaFilledIcon,
      },
    ];
  }, []);

  return (
    <Layout isBigHeader title={`Hello ${fetchDataSettings.data?.data?.nameShop},`}>
      <DashboardStyled>
        <div className="fz-18">
          <Text variant="bodyLg" as="span">
            Welcome to Age Verification Popup 🎉
          </Text>
        </div>

        {checkEmbeddedStatus.data && (
          <Collapsible
            id="banner-check-embedded-app"
            open={bannerNoticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(bannerSlice.actions.handleBannerNoticeEmbeddedApp(false));
                }}
                tone="warning"
                title="Age Verification Popup is not activated"
              >
                <div className="d-flex flex-column">
                  <RegularText>
                    Please enable the app by clicking the button below, and then click 'Save' in your Theme Editor. The popup will
                    not appear on your Online store until the app is enabled.
                  </RegularText>
                  <div className="mt-8">
                    <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                      Activate app
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        )}

        <ThingsToDoNext
          embedStatus={checkEmbeddedStatus.data?.status}
          hasCustomize={fetchDataSettings.data?.data.customizedSettings || false}
          hasTestApp={fetchDataSettings.data?.data.completedSetup || false}
          shop={fetchDataSettings.data?.data.shop || ''}
          isLoadingFetchDataSettings={fetchDataSettings.isLoading}
        />

        <div className="mt-16">
          <Overview />
        </div>
        <div className="card-info">
          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {infoAndSupport.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="card-container">
                    <div>
                      <div className="d-flex">
                        <Icon source={item.icon} />
                        <div className="ml-8">
                          <BoldText>{item.title}</BoldText>
                        </div>
                      </div>
                      <div className="card-text">
                        <RegularText>{item.description}</RegularText>
                      </div>
                    </div>
                    <div>
                      <Button onClick={item.onAction}>{item.btnContent}</Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>
        <div className="mt-16">
          <FAQ></FAQ>
        </div>
      </DashboardStyled>
    </Layout>
  );
};
export default Dashboard;
