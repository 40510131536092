import { PageType } from '@/constants/enum';
import { ProductOrCollectionListStyled } from './styled';
import useGetCollections from '@/hooks/useGetCollections';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { AutoSelection, Collapsible, Combobox, Icon, Listbox, Text } from '@shopify/polaris';
import { SearchIcon, SelectIcon, XSmallIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetProducts from '@/hooks/useGetProducts';

interface Props {
  errorProductOrCollection: string;
  setErrorProductOrCollection: React.Dispatch<React.SetStateAction<string>>;
}

const ProductOrCollectionList: React.FC<Props> = ({ errorProductOrCollection, setErrorProductOrCollection }) => {
  const dispatch = useDispatch();

  const previewDetail = useSelector(previewSelector);
  const { pageTypeToShow, showInPages } = previewDetail ?? {};

  const { collectionOptions, debouncedGetCollections, getCollectionsStatus } = useGetCollections();
  const { debouncedGetProducts, getProductsStatus, productOptions } = useGetProducts();

  const [valueSearch, setValueSearch] = useState('');

  const optionOfficial = pageTypeToShow === PageType.SpecificProducts ? productOptions : collectionOptions;

  const handleBlur = () => {
    if (showInPages?.length === 0) {
      const errorMessage = pageTypeToShow === PageType.SpecificProducts ? 'Product is required' : 'Collection is required';

      setErrorProductOrCollection(errorMessage);
    }
  };

  const updateSelection = useCallback(
    (selected: string) => {
      const currentSelected = showInPages?.map((item) => String(item.id));
      const isSelected = currentSelected.includes(selected);

      const newSelected = {
        title: optionOfficial.find((item) => item.value === selected)?.label,
        id: +selected,
      };

      const newInputValues = isSelected
        ? showInPages?.filter((item) => String(item.id) !== selected)
        : [...showInPages, newSelected];

      dispatch(handleChangePreview({ showInPages: newInputValues }));

      if (errorProductOrCollection) {
        setErrorProductOrCollection('');
      }
    },
    [showInPages, optionOfficial, dispatch, errorProductOrCollection, setErrorProductOrCollection],
  );

  const optionsMarkup =
    optionOfficial.length > 0
      ? optionOfficial.map(({ label, value }) => (
          <Listbox.Option
            key={value}
            value={value}
            selected={showInPages?.map((item) => String(item?.id))?.includes(value)}
            accessibilityLabel={label}
          >
            {label}
          </Listbox.Option>
        ))
      : null;

  const updateText = useCallback(
    (value: string) => {
      if (pageTypeToShow === PageType.SpecificCollections) {
        debouncedGetCollections(value);
      } else {
        debouncedGetProducts(value);
      }

      setValueSearch(value);
    },
    [debouncedGetCollections, debouncedGetProducts, pageTypeToShow],
  );

  const removeItem = (id: number) => {
    const newValues = showInPages?.filter((item) => item.id !== id);

    dispatch(handleChangePreview({ showInPages: newValues }));

    setErrorProductOrCollection(
      !newValues.length ? `${pageTypeToShow === PageType.SpecificCollections ? 'Collection' : 'Product'} is required` : '',
    );
  };

  const openCollapsible = pageTypeToShow === PageType.SpecificProducts || pageTypeToShow === PageType.SpecificCollections;

  return (
    <ProductOrCollectionListStyled>
      <Collapsible
        id="choice-product-or-collection"
        open={openCollapsible}
        expandOnPrint
        transition={{ timingFunction: 'ease-in-out', duration: '300ms' }}
      >
        <Combobox
          allowMultiple
          activator={
            <Combobox.TextField
              requiredIndicator
              prefix={<Icon source={SearchIcon} />}
              onChange={updateText}
              label={
                <Text as="span" variant="headingMd">
                  Value
                </Text>
              }
              value={valueSearch}
              placeholder={`Search ${pageTypeToShow === PageType.SpecificProducts ? 'product' : ' collection'}`}
              autoComplete="off"
              onBlur={handleBlur}
              disabled={getCollectionsStatus.isLoading || getProductsStatus.isLoading}
              error={errorProductOrCollection}
              id="ruleValue"
              suffix={<Icon source={SelectIcon} />}
            />
          }
        >
          {optionsMarkup ? (
            <Listbox autoSelection={AutoSelection.None} onSelect={updateSelection}>
              {optionsMarkup}
            </Listbox>
          ) : null}
        </Combobox>

        <div className="selected-tag-full-width">
          <div className="width-100 d-flex flex-wrap">
            {showInPages?.map(({ title, id }) => {
              return (
                <div className="mr-8 Polaris-Tag mb-4 break-word" key={id}>
                  <h1 title={title} className="Polaris-Tag__TagText">
                    {title}
                  </h1>
                  <button className="Polaris-Tag__Button" onClick={() => removeItem(id)}>
                    <Icon source={XSmallIcon}></Icon>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </Collapsible>
    </ProductOrCollectionListStyled>
  );
};

export default ProductOrCollectionList;
