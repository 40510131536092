import { BlockStack, Card, Divider } from '@shopify/polaris';
import React from 'react';

import ColorPickerCustom from '@/components/ColorPickerCustom';
import { BackgroundColor, RedirectUrl } from '@/constants/enum';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import BoldText from '@/components/BoldText';

interface ButtonSettingsProps { }

const ButtonSettings: React.FC<ButtonSettingsProps> = () => {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  return (
    <BlockStack gap="200">
      <Card>
        <BoldText>Submit button</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label="Text"
            value={previewDetail?.submitButtonLabel || ''}
            onChange={handleChangeTemplate('submitButtonLabel')}
            maxLength={32}
            showCharacterCount
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('submitButtonTextColor')}
            label="Text color"
            color={previewDetail.submitButtonTextColor}
            keyColor="submitButtonTextColor"
            defaultColor={BackgroundColor.White}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('submitButtonBackground')}
            label="Background"
            color={previewDetail.submitButtonBackground}
            keyColor="submitButtonBackground"
            defaultColor={BackgroundColor.BrightOrange}
          />
        </BlockStack>
      </Card>

      <Card>
        <BoldText>Cancel button</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label="Text"
            value={previewDetail?.cancelButtonLabel || ''}
            onChange={handleChangeTemplate('cancelButtonLabel')}
            maxLength={32}
            showCharacterCount
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('cancelButtonTextColor')}
            label="Text color"
            color={previewDetail.cancelButtonTextColor}
            keyColor="cancelButtonTextColor"
            defaultColor={BackgroundColor.White}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('cancelButtonBackground')}
            label="Background"
            color={previewDetail.cancelButtonBackground}
            keyColor="cancelButtonBackground"
            defaultColor={BackgroundColor.LightGray}
          />

          <TextField
            autoComplete=""
            label="Redirect URL"
            value={previewDetail.redirectUrl}
            onChange={handleChangeTemplate('redirectUrl')}
            onBlur={() => {
              if (!previewDetail.redirectUrl.trim().length) handleChangeTemplate('redirectUrl')(RedirectUrl.Google);
            }}
          />

          <TextField
            autoComplete=""
            label="Error message"
            value={previewDetail?.errorMessage || ''}
            onChange={handleChangeTemplate('errorMessage')}
            placeholder="Enter error message"
            maxLength={255}
            showCharacterCount
          />
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default ButtonSettings;
