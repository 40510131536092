import BoldText from '@/components/BoldText';
import ColorPickerCustom from '@/components/ColorPickerCustom';
import { BackgroundColor } from '@/constants/enum';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Card, Divider, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const TextSetting = () => {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    if (key === 'headingSize' || key === 'subHeadingSize') {
      // Loại bỏ dấu '-' nếu có trong chuỗi đầu vào
      value = value.replace(/-/g, '');

      const isValidNumber = /^[0-9]\d*$/.test(value); // Chỉ cho phép số nguyên dương

      if (isValidNumber) {
        let numericValue = parseInt(value, 10);

        if (numericValue > 36) {
          numericValue = 36;
        }

        value = numericValue.toString();
      } else {
        value = '';
      }
    }

    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  return (
    <BlockStack gap="200">
      <Card>
        <BoldText>Pop-up heading</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label="Text"
            value={previewDetail?.headingText || ''}
            onChange={handleChangeTemplate('headingText')}
            maxLength={255}
            showCharacterCount
          />

          <TextField
            autoComplete=""
            label="Size"
            value={previewDetail.headingSize}
            onChange={handleChangeTemplate('headingSize')}
            suffix="px"
            onBlur={() => {
              if (Number(previewDetail.headingSize) === 0) {
                handleChangeTemplate('headingSize')('20');
              }
            }}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('headingColor')}
            label="Color"
            color={previewDetail.headingColor}
            keyColor="headingColor"
            defaultColor={BackgroundColor.White}
          />
        </BlockStack>
      </Card>

      <Card>
        <BoldText>Pop-up sub-heading</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label="Text"
            value={previewDetail?.subHeadingText || ''}
            onChange={handleChangeTemplate('subHeadingText')}
            maxLength={255}
            showCharacterCount
          />

          <TextField
            autoComplete=""
            label="Size"
            value={previewDetail.subHeadingSize}
            onChange={handleChangeTemplate('subHeadingSize')}
            suffix="px"
            onBlur={() => {
              if (Number(previewDetail.subHeadingSize) === 0) {
                handleChangeTemplate('subHeadingSize')('16');
              }
            }}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('subHeadingColor')}
            label="Color"
            color={previewDetail.subHeadingColor}
            keyColor="subHeadingColor"
            defaultColor={BackgroundColor.White}
          />
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default TextSetting;
