import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { BlockStack, Card, Collapsible, Icon } from '@shopify/polaris';
import { MinusIcon, PlusIcon } from '@shopify/polaris-icons';
import React, { FC, useEffect, useMemo, useState } from 'react';

interface FAQItem {
  label: string;
  extend: boolean;
  content: React.ReactNode;
  mixpanelEvent: string;
}

const FAQ: FC = () => {
  const initialData: FAQItem[] = useMemo(
    () => [
      {
        label: '1. Does this app cost me monthly?',
        extend: false,
        content: (
          <RegularText>
            No, the app is completely <b>free</b>, and you can customize the pop-up without any limitations.
          </RegularText>
        ),
        mixpanelEvent: 'First_question_faq',
      },
      {
        label: '2. Is this app compatible with all Shopify themes?',
        extend: false,
        content: (
          <>
            <RegularText>
              Yes, the app works seamlessly with all Shopify themes.
            </RegularText>
          </>
        ),
        mixpanelEvent: 'Second_question_faq',
      },
      {
        label: '3. Is this app compatible with custom themes?',
        extend: false,
        content: <RegularText>Currently, custom themes or those purchased from third-party markets (not Shopify) may have occasional CSS issues. However, the impact is minimal. Please contact support if you get any problems with it.</RegularText>,
        mixpanelEvent: 'Third_question_faq',
      },
    ],
    [],
  );

  const [listData, setListData] = useState<FAQItem[]>([]);

  useEffect(() => {
    setListData(initialData);
  }, [initialData]);

  const handleToggle = (index: number) => {
    setListData((prevData) =>
      prevData.map((item, i) => {
        if (i === index) {
          return { ...item, extend: !item.extend };
        }
        return { ...item, extend: false };
      }),
    );
  };

  return (
    <Card padding={{ xs: '400', lg: '400' }}>
      <div className="mb-16">
        <BoldText>Frequently Ask Questions</BoldText>
      </div>
      <BlockStack gap="500">
        {listData.map((question, index) => (
          <div className="faq-item-container" key={question.label}>
            <Card>
              <div className='faq-item-label' onClick={() => handleToggle(index)}>
                {question.label}
              </div>
            </Card>
            <div className="faq-extend-status">
              <Icon source={!question.extend ? PlusIcon : MinusIcon} />
            </div>

            <Collapsible
              open={question.extend}
              id="basic-collapsible"
              transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
            >
              <div className="faq-content">
                <RegularText>{question.content}</RegularText>
              </div>
            </Collapsible>
          </div>
        ))}
      </BlockStack>
    </Card>
  );
};

export default FAQ;
