import { config } from '@/config';
import { PATH } from '@/constants/path';
// import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { Button, ButtonGroup, Frame, Link, Page, PageProps } from '@shopify/polaris';
import { HomeIcon } from '@shopify/polaris-icons';
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegularText from '../RegularText';
import SkeletonPage from '../SkeletonPage';
import Toast from '../Toast/Toast';
import { LayoutStyled } from './styled';
import { Embedded } from '@/constants/enum';

const btnGroup = [
  {
    content: 'Dashboard',
    icon: HomeIcon,
    path: PATH.DEFAULT,
  },
  {
    content: 'Pop-up',
    icon: HomeIcon,
    path: PATH.POP_UP_CUSTOMIZATION,
  },
  {
    content: 'Setting',
    icon: HomeIcon,
    path: PATH.SETTINGS,
  },
];

interface ILayoutProps extends PageProps {
  headers?: React.ReactNode;
  isBigHeader?: boolean;
}

const Layout = ({ ...props }: ILayoutProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  // const isLoading = useSelector(generalSettingsLoadingSelector);
  const isLoading = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <LayoutStyled isBigHeader={props.isBigHeader}>
      <Frame>
        <Toast />
        {config.embedded !== Embedded.LIVE ? (
          <div className="nav-bar">
            <ButtonGroup fullWidth gap="extraTight">
              {btnGroup.map((item, index) => {
                return (
                  <Button
                    key={index}
                    icon={item.icon}
                    variant={
                      location.pathname === item.path.pathname || (index === 0 && location.pathname === '/')
                        ? 'primary'
                        : undefined
                    }
                    onClick={() => {
                      navigate({
                        ...item.path,
                      });
                    }}
                  >
                    {item.content}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        ) : null}
        {props.headers || null}
        <Page {...props}>
          {isLoading ? (
            <SkeletonPage />
          ) : (
            <div>
              <div>{props.children}</div>
              <RegularText>
                <div className="layout-footer">
                  Need help? Please view&nbsp;{' '}
                  <Link
                    removeUnderline
                    external
                    url='https://docs-age.ipblocker.io/'
                  >
                    our document guideline
                  </Link>
                </div>
              </RegularText>
            </div>
          )}
        </Page>
      </Frame>
    </LayoutStyled>
  );
};
export default memo(Layout);
