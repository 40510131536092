import styled from 'styled-components';

export const DashboardStyled = styled.div`
  .most-popular-item {
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ddd;
    .most-popular-item-left {
      align-items: center;
    }
    button {
      height: fit-content;
      white-space: nowrap;
    }
    .mt-4 {
      margin-top: 4px;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .card-info {
    margin-top: 16px;
    .Polaris-Icon {
      margin: 0;
    }
    .card-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0.5rem;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
    }
  }
  .faq-item-container {
    position: relative;
    .faq-item-label {
      max-width: 90%;
    }
    .faq-extend-status {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .faq-content {
      padding: 1rem;
      background-color: #e3e3e3;
      border-radius: 5px;
      margin-top: 0.5rem;
    }
    .Polaris-Button {
      padding: 12px;
    }
  }
`;
