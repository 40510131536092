import { config } from '@/config';
import { IResponseApi } from '@/types/apis/response';
import { IParamsApi } from '@/types/apis/params';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../store';
import authSlice from '../slice/auth.slice';
import { IPreview } from '@/types/components/preview';
import { Embedded } from '@/constants/enum';

const params = {
  shop: config.shop,
};
const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  host: new URLSearchParams(window.location.search).get('host') || '',
  forceRedirect: process.env.REACT_APP_MODE === 'live',
};
export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_END_POINT,
    prepareHeaders: async (headers) => {
      if (config.embedded === Embedded.LIVE) {
        if (
          (!store.getState().auth.token || Date.now() > store.getState().auth.expiredTime) &&
          !store.getState().auth.isLoading
        ) {
          try {
            store.dispatch(authSlice.actions.handleLoading(true));
            const app = createApp(configApp);
            const sessionToken = await getSessionToken(app);
            store.dispatch(
              authSlice.actions.handleToken({
                token: sessionToken,
                expiredTime: Date.now() + 60 * 1000,
                isLoading: false,
              }),
            );
            headers.set('authorization', `Bearer ${sessionToken}`);
          } catch (err) {
            store.dispatch(authSlice.actions.handleLoading(false));
          }
        }
        if (store.getState().auth.token) {
          headers.set('authorization', `Bearer ${store.getState().auth.token}`);
        }
      }

      if (process.env.REACT_APP_ENV === 'production') {
        headers.set('Content-Security-Policy', `frame-ancestors https://${params.shop} https://admin.shopify.com`);
      }
      headers.set('authorization-url-params', config.urlParams);

      return headers;
    },
  }),
  tagTypes: ['general', 'preview', 'overview'],
  // get data settings
  endpoints: (builder) => ({
    getGeneralDetail: builder.query<IResponseApi.GeneralDetail, string | undefined>({
      query: () => {
        return {
          url: `shop/general-setting`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'general' }],
    }),
    checkAppEmbedStatus: builder.query<{ status: boolean }, void>({
      query: () => {
        return {
          url: 'shopify/check-app-extension',
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    getOverview: builder.query<IResponseApi.IOverview, IParamsApi.IOverview>({
      query: (input) => {
        return {
          url: `analytics`,
          method: 'GET',
          params: { ...params, startDate: input.startDate, endDate: input.endDate },
        };
      },
      providesTags: [{ type: 'overview' }],
    }),

    getShopDomain: builder.query<IResponseApi.IDomain, undefined>({
      query: () => {
        return {
          url: `shop/domain`,
          method: 'GET',
          params: { ...params },
        };
      }
    }),

    uploadImage: builder.mutation<IResponseApi.IUploadImage, FormData>({
      query: (image) => {
        return {
          url: `file/upload-image`,
          method: 'POST',
          body: image,
          params: { ...params },
        };
      },
    }),

    updateTemplate: builder.mutation<IResponseApi.CommonResponse, IPreview>({
      query: (input) => {
        return {
          url: `settings`,
          method: 'PUT',
          params: { ...params },
          body: input,
        };
      },
      invalidatesTags: [{ type: 'preview' }],
    }),

    getSettings: builder.query<IResponseApi.IGetSettings, void>({
      query: () => {
        return {
          url: 'settings',
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'preview' }],
    }),

    getPages: builder.query<IResponseApi.IGetPages, void>({
      query: () => {
        return {
          url: 'shopify/pages',
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    trackAction: builder.mutation<void, string>({
      query: (event) => {
        return {
          url: 'tracking',
          method: 'POST',
          params: { ...params },
          body: {
            shop: params.shop,
            event,
          },
        };
      },
    }),

    getProducts: builder.query<IResponseApi.IGetProductList, { search: string; isGiftCard?: boolean }>({
      query: (input) => {
        return {
          url: `shopify/products/search`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    getCollections: builder.query<IResponseApi.IGetCollectionList, { search: string }>({
      query: (input) => {
        return {
          url: `shopify/collections/search`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),
  }),
});
